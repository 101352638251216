
// import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
// import ModalDialogo from "@/components/ModalDialogo.vue"
import { Modal } from "bootstrap";
import s3 from '../../../services/S3Service'
import Api from "@/services/Api";
// import useAlert from '@/composables/Alert'
// import useEmitter from "@/composables/Emmiter";
import useAlert from "../../../composables/Alert";



export default defineComponent({
  name: "ModalPlanilhaVeiculo",
  components: {
    // ModalDialogo,
  },
  props: {
    arquivo: Object as PropType<any>,
    rodandoFila: Boolean,
    alteraRodandoFila: Function,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert();

    const arquivo: Ref<any> = ref({});

    const modalDialogo: Ref<any> = ref()


    function fechaModal(){

      arquivo.value = {};
      props.fechaModal && props.fechaModal()

    }

    function abreModal(){

      arquivo.value = props.arquivo

      console.log(arquivo.value)
    
    }



    async function baixaPlanilha() {

      const url = s3.getSignedUrl('getObject', { Key: arquivo.value.pasta })

      console.log('url', url)

      const nomeArquivo = arquivo.value.nome.replace('AtriaLub/planilhas/veiculos/', '') + '.xlsx'

      const link = document.createElement('a');
      link.href = url;
      link.download = nomeArquivo;
      link.click();

    }

    async function rodaPlanilha() {

      props.alteraRodandoFila && props.alteraRodandoFila(true)

      try {

        

        const nomeArquivo = arquivo.value.nome.replace('AtriaLub/planilhas/veiculos/', '')
  
        const { data } = await Api.get("processaFilaPorNome", { nome: nomeArquivo })
  
        if(data.erro) return showTimeAlert(data.mensagem, "error")
  
        showTimeAlert(data.mensagem)


      } catch (error) { console.log(error); showTimeAlert('ocorreu um erro inesperado!', "error") }

      props.alteraRodandoFila && props.alteraRodandoFila(false)

    }

    


    // abrindo modal com usuario
    watch(() => props.arquivo, async () => { abreModal() }, { deep: true })

    onMounted(async()=>{

      // modalDialogo.value = new Modal(document.querySelector(`#ModalDialogo`));

    })
   
    return {
      arquivo,
      fechaModal,
      modalDialogo,
      baixaPlanilha,
      rodaPlanilha,
    };
  },
});
